import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Bivak } from '../entities/Bivak';
import AuthService from '../services/AuthService';
import CommentComponent from '../components/CommentComponent';
import ErrorModal from '../components/modals/ErrorModalComponent';
import AddImageModal from '../components/modals/AddImageModalComponent';
import { Image } from '../entities/Image';
import AppConfig from '../appconfig';
import ErrorMessages from '../ErrorMessages';
import ImageGalleryComponent from '../components/ImageGalleryComponent';
import FavoriteButton from '../components/buttons/FavoriteButton';
import ReservationComponent from '../components/ReservationsComponent';

const BivakDetail = () => {
    const { id } = useParams();
    const [bivak, setBivak] = useState<Bivak | null>(null);
    const [user, setUser] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [likeCount, setLikeCount] = useState(0);
    const [dislikeCount, setDislikeCount] = useState(0);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isImageModalOpen, setImageModalOpen] = useState(false);

    const handleRating = async (isLike: boolean) => {
        const formData = new FormData();
        formData.append('isLike', isLike.toString());

        try {
            const response = await AuthService.fetchWithAuth(`${AppConfig.ApiBaseUrl}api/ratings/${id}`, {
                method: 'POST',
                body: formData,
            });

            const result = await response.json();
            if (response.ok) {
                if (isLike) {
                    setLikeCount(prev => prev + 1);
                } else {
                    setDislikeCount(prev => prev + 1);
                }
            } else {
                setErrorMessage('Failed to submit rating:' + result.message);
                setIsErrorModalOpen(true);
            }
        } catch (error: any) {
            setErrorMessage(AuthService.isLogged() ? error.message : ErrorMessages.NotSignedIn);
            setIsErrorModalOpen(true);
        }
    };

    const addNewImagesToBivak = (newImages: Image[]) => {
        setBivak(prevBivak => {
            if (prevBivak === null) return null;
    
            return {
                ...prevBivak,
                images: [...([]), ...newImages]
            };
        });
    };

    const fetchBivakDetails = async () => {
        try {
            const response = await fetch(`${AppConfig.ApiBaseUrl}api/bivaks/${id}`);
            if (!response.ok) throw new Error('Network response was not ok');
            const data = await response.json();
            setBivak(data);

            const { likes, dislikes } = data.ratings.reduce((acc: any, rating: any) => {
                if (rating.isLike) acc.likes++;
                else acc.dislikes++;
                return acc;
            }, { likes: 0, dislikes: 0 });

            setLikeCount(likes);
            setDislikeCount(dislikes);

            const responseUser = await fetch(`${AppConfig.ApiBaseUrl}api/users/${data.userId}`);
            const dataUser = await responseUser.json();
            setUser(dataUser);
            setLoading(false);
        } catch (error: any) {
            setError(error.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        // Fetch bivak details from the API
        fetchBivakDetails();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, likeCount, dislikeCount]);

    if (loading) {
        return <div>Hledám místo na spaní...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!bivak) {
        return <div>No bivak found</div>;
    }

    return (
        <div className="max-w-screen-xl text-left container mx-auto p-4">
            <div className='mb-6'>
                <div className='flex items-center space-x-3 justify-between'>
                    <h2 className="text-5xl font-bold mb-6 mt-4">{bivak.name}</h2>
                    <FavoriteButton bivakId={bivak.id!} />
                </div>
                <div className="flex items-center space-x-2 mb-4">
                    <img src="/c-marker-icon.png" alt="map marker" />
                    <p>{bivak.position.latitude}, {bivak.position.longitude}</p>
                </div>                
                <p><span className='font-bold'>Autor:</span> {user}</p>
                <p><span className='font-bold'>Kapacita:</span> {bivak.capacity}</p>
                <p><span className='font-bold'>Popis:</span> {bivak.description}</p>
            </div>
            
            <a 
                href={`https://mapy.cz/turisticka?q=${bivak.position.latitude}%2C%20${bivak.position.longitude}`}
                target="_blank"
                rel="noopener noreferrer"
                className="rounded text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-4 py-2 bg-black text-white hover:bg-gray-800 border-2 border-transparent"
            >
                Otevřít v Mapy.cz
            </a>

            <div className="rating-buttons mt-6 flex space-x-4">
                <button
                onClick={() => handleRating(true)}
                className="bg-white border hover:bg-gray-100 border-gray-400 text-black font-bold py-2 px-4 rounded flex items-center space-x-2"
                >
                <i className="fas fa-thumbs-up"></i>
                <span>{likeCount}</span>
                </button>
                <button
                onClick={() => handleRating(false)}
                className="bg-white border hover:bg-gray-100 border-gray-400 text-black font-bold py-2 px-4 rounded flex items-center space-x-2"
                >
                <i className="fas fa-thumbs-down"></i>
                <span>{dislikeCount}</span>
                </button>
            </div>

            <ReservationComponent bivak={bivak}/>
            <ImageGalleryComponent bivak={bivak} />

            <div>
                <button onClick={() => setImageModalOpen(true)} className="rounded text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-4 py-2 bg-black text-white hover:bg-gray-800 border-2 border-transparent">
                    Přidat fotku
                </button>
                <AddImageModal onImagesAdded={addNewImagesToBivak} isOpen={isImageModalOpen} onClose={() => setImageModalOpen(false)} bivakId={bivak.id!} />
            </div>

            <div>
                <ErrorModal isOpen={isErrorModalOpen} message={errorMessage} onClose={() => setIsErrorModalOpen(false)} />
            </div>

            <CommentComponent bivakId={bivak.id!} />
        </div>
    );
};

export default BivakDetail;
