// src/components/AccommodationItem.tsx
import React from 'react';
import { Bivak } from '../entities/Bivak';
import { useNavigate } from 'react-router-dom';
import AppConfig from '../appconfig';

interface AccommodationItemProps {
    bivak: Bivak;
}

const AccommodationItem = ({ bivak }: AccommodationItemProps) => {
    const navigate = useNavigate(); // Hook to get the navigate function

    // Build image URL using the first image in the array, if available
    const imageUrl = bivak.images && bivak.images.length > 0
        ? `${AppConfig.ImagesPath}/images/${(bivak.images[0].fileName)}`
        : '/default-image.png';

    // Handler for the click event
    const handleClick = () => {
        // Redirect to the detail page, you might pass the bivak ID in the URL if needed
        navigate(`/detail/${bivak.id}`);
    };

    return (
        <div className="cursor-pointer border border-gray-200 rounded-md p-6 mb-4 shadow flex justify-between" onClick={handleClick}>
            <div className="text-left flex-1">
                <h3 className="text-2xl font-bold">{bivak.name}</h3>
                <p className="text-sm text-gray-600">Kapacita: {bivak.capacity}</p>
                <div className="h-16 overflow-hidden text-ellipsis">
                    <p className="text-sm text-gray-500">
                        Popis: {bivak.description ? (bivak.description.length > 500 ? `${bivak.description.substring(0, 500)}...` : bivak.description) : 'No description available.'}
                    </p>
                </div>
            </div>
            <img src={imageUrl} alt={bivak.name} className="w-48 h-48 object-cover ml-4 rounded-md" />
        </div>
    );
};


export default AccommodationItem;
