import React, { useCallback, useEffect, useState } from 'react';
import AppConfig from '../appconfig';
import { Bivak } from '../entities/Bivak';
import { Image } from '../entities/Image';

interface ImageGalleryProps {
    bivak: Bivak;
}

const ImageGalleryComponent = ({ bivak }: ImageGalleryProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);

  const handleNextImage = useCallback(() => {
    if (bivak.images && selectedImage) {
      const currentIndex = bivak.images.indexOf(selectedImage);
      const nextIndex = (currentIndex + 1) % bivak.images.length;
      setSelectedImage(bivak.images[nextIndex]);
    }
  }, [bivak.images, selectedImage]);

  const handlePreviousImage = useCallback(() => {
    if (bivak.images && selectedImage) {
      const currentIndex = bivak.images.indexOf(selectedImage);
      const previousIndex = (currentIndex - 1 + bivak.images.length) % bivak.images.length;
      setSelectedImage(bivak.images[previousIndex]);
    }
  }, [bivak.images, selectedImage]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setSelectedImage(null);
  }, []);

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (isModalOpen) {
      switch (event.key) {
        case 'ArrowRight':
          handleNextImage();
          break;
        case 'ArrowLeft':
          handlePreviousImage();
          break;
        case 'Escape':
          handleCloseModal();
          break;
        default:
          break;
      }
    }
  }, [isModalOpen, handleNextImage, handlePreviousImage, handleCloseModal]);

  const handleImageClick = useCallback((image: Image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [isModalOpen, handleKeyDown]);

  return (
    <div>
      <p className='text-2xl font-bold'>Fotografie</p>
      <div className="flex flex-wrap justify-start items-center mb-2">
        {bivak.images && bivak.images.length > 0 ? (
          bivak.images.map((image, index) => (
            <img
              key={index}
              src={`${AppConfig.ImagesPath}/images/${image.fileName}`}
              alt={`${bivak.name} ${index + 1}`}
              className="w-1/4 h-auto ml-0 mr-4 mb-2 mt-2 rounded cursor-pointer"
              onClick={() => handleImageClick(image)}
            />
          ))
        ) : (
          <img src="/default-image.png" alt="Default" className="w-1/4 h-auto m-2 rounded" />
        )}
      </div>

      {isModalOpen && selectedImage && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div className="bg-white p-4 rounded max-w-2xl relative w-11/12 h-5/6 flex flex-col">
      <button
        className="absolute top-4 right-4 text-black"
        onClick={handleCloseModal}
      >
        <i className="fa-solid fa-xmark"></i>
      </button>
      <button
        className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white text-black p-2 rounded-full"
        onClick={handlePreviousImage}
      >
        <i className="fa-solid fa-chevron-left"></i>
      </button>
      <button
        className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white text-black p-2 rounded-full"
        onClick={handleNextImage}
      >
        <i className="fa-solid fa-chevron-right"></i>
      </button>
      <div className="flex-grow flex items-center justify-center overflow-hidden">
        <img
          src={`${AppConfig.ImagesPath}/images/${selectedImage.fileName}`}
          alt={`${bivak.name}`}
          className="max-w-full max-h-full object-contain"
        />
      </div>
      <div className="mt-4 flex justify-center overflow-x-auto">
        <div className="flex">
          {bivak.images && bivak.images.map((image, index) => (
            <img
              key={index}
              src={`${AppConfig.ImagesPath}/images/${image.fileName}`}
              alt={`${bivak.name} ${index + 1}`}
              className={`w-1/6 h-auto m-1 rounded cursor-pointer ${
                image === selectedImage ? 'border-2 border-black' : ''
              }`}
              onClick={() => setSelectedImage(image)}
            />
          ))}
        </div>
      </div>
    </div>
  </div>
)}
    </div>
  );
};

export default ImageGalleryComponent;
