import React, { useState } from 'react';
import AuthService from '../../services/AuthService';
import { Image } from '../../entities/Image';
import { Bivak } from '../../entities/Bivak';
import AppConfig from '../../appconfig';
import ErrorMessages from '../../ErrorMessages';
import ErrorModal from './ErrorModalComponent';

interface AddImageModalProps {
    onImagesAdded: (newImages: Image[]) => void;
    isOpen: boolean;
    onClose: () => void;
    bivakId: number;
}

const AddImageModal = ({ onImagesAdded, isOpen, onClose, bivakId }: AddImageModalProps) => {
    const [images, setImages] = useState<File[]>([]);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setImages([...images, ...Array.from(event.target.files)]);
        }
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (event.dataTransfer.files) {
            setImages([...images, ...Array.from(event.dataTransfer.files)]);
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData();

        images.forEach((file, index) => {
            formData.append(`images[${index}]`, file, file.name);
        });

        try {
            const response = await AuthService.fetchWithAuth(`${AppConfig.ApiBaseUrl}api/images/${bivakId}`, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                setErrorMessage(AuthService.isLogged() ? response.statusText : ErrorMessages.NotSignedIn);
                setIsErrorModalOpen(true);
            }

            const addedImages: Bivak = await response.json();  // Assuming this returns the added images
            onImagesAdded(addedImages.images!);
            onClose(); // Close the modal on success
        } catch (error: any) {
            setErrorMessage(AuthService.isLogged() ? error.message : ErrorMessages.NotSignedIn);
            setIsErrorModalOpen(true);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                <div className="mt-3 text-center">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Přidat fotky</h3>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">Vyberte fotky k bivaku nebo je přetáhněte sem.</p>
                    </div>
                    <form onSubmit={handleSubmit} className="mt-4">
                        <label
                            htmlFor="file-upload"
                            className="mb-3 p-2 w-full border rounded cursor-pointer bg-black text-white flex justify-center items-center"
                        >
                            <i className="fa-solid fa-plus mr-2"></i> Přidat
                        </label>
                        <input
                            id="file-upload"
                            type="file"
                            multiple
                            accept="image/*"
                            onChange={handleImageChange}
                            className="hidden"
                        />
                        <div
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                            className="border-dashed border-2 border-gray-300 p-6 rounded mt-4 text-center"
                        >
                            Přetáhněte fotky sem nebo klikněte pro přidání.
                        </div>
                        <div className="flex flex-wrap justify-center mt-3">
                            {images.map((file, index) => (
                                <div key={index} className="m-1">
                                    <img src={URL.createObjectURL(file)} alt={`Místo na spaní ${index}`} className="w-20 h-20 object-cover rounded-md" />
                                </div>
                            ))}
                        </div>
                        <div className="items-center px-4 py-3">
                            <button
                                type="submit"
                                className="px-4 py-2 rounded text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 w-full bg-black text-white hover:bg-gray-800 border-2 border-transparent"
                            >
                                Nahrát
                            </button>
                        </div>
                    </form>
                    <div className="items-center px-4 py-3">
                        <button
                            onClick={onClose}
                            className="px-4 py-2 bg-gray-500 text-white text-base rounded w-full hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500"
                        >
                            Zrušit
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <ErrorModal isOpen={isErrorModalOpen} message={errorMessage} onClose={() => setIsErrorModalOpen(false)} />
            </div>
        </div>
    );
};

export default AddImageModal;
