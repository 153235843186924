import React from 'react';
import './App.css';
import Map from './pages/Map';
import HeaderComponent from './components/HeaderComponent';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import AddBivak from './pages/AddBivak';
import BivakDetail from './pages/BivakDetail';
import Login from './pages/Login';
import Register from './pages/Register';
import AccommodationList from './pages/AccommodationList';
import LandingPage from './pages/LandingPage';
import Profile from './pages/Profile';

function App() {
  return (
    <Router>
      <div className="App h-screen">
        <HeaderComponent />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/list" element={<AccommodationList />} />
          <Route path="/map" element={<Map />} />
          <Route path="/add" element={<AddBivak />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/detail/:id" element={<BivakDetail />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
