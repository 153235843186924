import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import { cs } from 'date-fns/locale';
import { format } from 'date-fns';

registerLocale('cs', cs);

interface DatePickerComponentProps {
  onDateSelect: (date: Date) => void;
}

const DatePickerComponent = ({ onDateSelect }: DatePickerComponentProps) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  
  const formatDate = (): string => {
    const today = new Date();
    return format(today, 'dd.MM.yyyy');
  };

  const handleDateChange = (date: Date | null) => {
    date?.setHours(8);
    setSelectedDate(date);
  };

  const handleAddReservation = () => {
    if (selectedDate) {
      onDateSelect(selectedDate);
    }
  };

  return (
    <div className="datepicker mt-3 flex space-x-8">
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat="dd.MM.yyyy"
        locale="cs"
        className="date-picker-input"
        placeholderText={formatDate()}
      />
      <button className='px-4 py-2 bg-black hover:bg-gray-800 text-white rounded transition' 
        onClick={handleAddReservation}>Přidat rezervaci</button>
    </div>
  );
};

export default DatePickerComponent;
