import React, { useState, useEffect, ChangeEvent } from 'react';
import { Bivak } from '../entities/Bivak';
import { Pagination } from '../entities/Pagination';
import AccommodationItem from '../components/AccommodationItemComponent';
import AppConfig from '../appconfig';

const AccommodationList = () => {
    const [bivaks, setBivaks] = useState<Bivak[]>([]);
    const [pagination, setPagination] = useState<Pagination | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [appliedSearchQuery, setAppliedSearchQuery] = useState<string>('');
    const [sortByLikes, setSortByLikes] = useState(false);

    const fetchData = async (page: number = 1) => {
        setLoading(true);
        try {
            let request = `${AppConfig.ApiBaseUrl}api/bivaks?pagenumber=${page}&pagesize=10`;

            if (searchQuery) {
                request += `&bivakName=${searchQuery}`;
            }
            if (sortByLikes) {
                request += `&sortBy=likes`;
            }

            const response = await fetch(request);
            if (!response.ok) throw new Error('Network response was not ok');
            const data = await response.json();

            const paginationHeader = response.headers.get('X-Pagination');

            if (paginationHeader) {
                const paginationData : Pagination = JSON.parse(paginationHeader);

                setPagination({
                    CurrentPage: paginationData.CurrentPage,
                    TotalPages: paginationData.TotalPages,
                    PageSize: paginationData.PageSize,
                    TotalCount: paginationData.TotalCount,
                    HasNext: paginationData.TotalPages > paginationData.CurrentPage,
                    HasPrevious: paginationData.CurrentPage > 1
                });
            }
            
            setBivaks(data);
        } catch (err) {
            console.error('Failed to fetch Bivaks:', err);
            setError('Failed to fetch data.');
        } finally {
            setLoading(false);
        }
    };

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    const handleSearchClick = () => {
        setAppliedSearchQuery(searchQuery);
    };

    const handleSortChange = () => {
        setSortByLikes(!sortByLikes);
    };

    const sortByDefault = () => {
        setSortByLikes(false);
    };

    useEffect(() => {
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appliedSearchQuery, sortByLikes]);

    if (loading) return <div>Hledám ti spaní</div>;
    if (error) return <div>Chyba, nenašel jsem nic na spaní: {error}</div>;

    return (
        <div className="max-w-screen-xl mx-auto px-5 mt-8">
            <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
                <div className="flex flex-col sm:flex-row mb-2 sm:mb-0">
                    <input 
                        type="text" 
                        placeholder="Hledej místa" 
                        value={searchQuery} 
                        onChange={handleSearchChange} 
                        className="px-4 py-2 mb-2 sm:mb-0 sm:mr-2 border rounded shadow-sm focus:outline-none focus:border-pink-600"
                    />
                    <button 
                        onClick={handleSearchClick} 
                        className="px-4 py-2 rounded bg-black text-white hover:bg-gray-800 border-2 border-transparent sm:mr-2"
                    >
                        Hledat
                    </button>
                </div>
                <div className="flex">
                    <button 
                        onClick={sortByDefault} 
                        className={`px-4 py-2 mr-2 rounded-md shadow-sm focus:outline-none focus:ring-2 ${!sortByLikes ? 'bg-pink-600 text-white' : 'bg-gray-200 text-black hover:bg-gray-300'}`}
                    >
                        Nejnovější
                    </button>
                    <button 
                        onClick={handleSortChange} 
                        className={`px-4 py-2 rounded-md shadow-sm focus:outline-none focus:ring-2 ${sortByLikes ? 'bg-pink-600 text-white' : 'bg-gray-200 text-black hover:bg-gray-300'}`}
                    >
                        Nejoblíbenější
                    </button>
                </div>
            </div>

            {bivaks.length > 0 ? bivaks.map(bivak => (
                <AccommodationItem key={bivak.id} bivak={bivak} />
            )) : <p>Žádné místo na spaní tu není</p>}
            {pagination && (
                <div className="flex justify-between">
                    <button onClick={() => fetchData(pagination.CurrentPage - 1)} disabled={!pagination.HasPrevious} className="disabled:text-white disabled:bg-white mb-8 rounded text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-4 py-2 bg-black text-white hover:bg-gray-800  border-2 border-transparent">Předchozí</button>
                    <button onClick={() => fetchData(pagination.CurrentPage + 1)} disabled={!pagination.HasNext} className="disabled:text-white disabled:bg-white mb-8 rounded text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-4 py-2 bg-black text-white hover:bg-gray-800  border-2 border-transparent">Další</button>
                </div>
            )}
        </div>
    );
};

export default AccommodationList;
