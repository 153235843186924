import { useEffect, useState } from "react";
import AppConfig from "../appconfig";
import { Bivak } from "../entities/Bivak";
import AccommodationItem from "../components/AccommodationItemComponent";

const LandingPage = () => {
    const [topBivaks, setTopBivaks] = useState<Bivak[]>();
    
    const fetchTopBivaks = async () => {
        const response = await fetch(`${AppConfig.ApiBaseUrl}api/bivaks?pagenumber=1&pagesize=5&sortBy=likes`);

        if (response.ok)
        {
            const bivaks: Bivak[] = await response.json();
            setTopBivaks(bivaks);
        }
    }

    useEffect(() => {
        fetchTopBivaks();
    }, []);

    return (
        <div className="max-w-screen-xl mx-auto px-5">
            <div className="grid lg:grid-cols-2 place-items-center pt-16 pb-8 md:pt-12 md:pb-24">
                <div className='text-center md:text-left'>
                    <h1 className='text-5xl lg:text-6xl xl:text-7xl font-bold lg:tracking-tight xl:tracking-tighter'>Čau dobrodruhu, nevíš <span className='text-[#fc336c]'>Kde Spát</span>?</h1>
                    <p className='text-lg mt-4 mb-6 md:mb-0 text-slate-600 max-w-xl'>Hledáš místo, kde se dá přespat pod širákem? Kde Spát je přesně pro tebe! Tady najdeš tipy na super místa na přespání v přírodě, můžeš sdílet svoje objevy a pomoct ostatním dobrodruhům najít parádní nocleh. Přidej se k nám a pojďme spolu objevovat krásy přírody. Probudit se s výhledem, který stojí za to, nikdy nebylo snazší!</p>
                </div>
                <div>
                    <img src="./mainPageImage.png" alt="Spaní pod hvězdami"/>
                </div>
            </div>
            <div className="pb-5">
                <p className="text-3xl font-bold mb-4">Nejoblíbenější místa na spaní</p>
                {topBivaks?.map((bivak) => <AccommodationItem key={bivak.id} bivak={bivak} />)}
            </div>
        </div>
    );
};

export default LandingPage;
