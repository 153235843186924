import React, { useState, useEffect } from 'react';
import AuthService from '../services/AuthService'; // Ensure AuthService is correctly typed as well.
import { Comment } from '../entities/Comment';
import AppConfig from '../appconfig';
import ErrorMessages from '../ErrorMessages';
import ErrorModal from './modals/ErrorModalComponent';

interface CommentFormProps {
    bivakId: number;
}

const CommentComponent = ({ bivakId }: CommentFormProps) => {
    const [comment, setComment] = useState('');
    const [comments, setComments] = useState<Comment[]>([]);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const fetchComments = async () => {
        try {
            const response = await AuthService.fetchWithAuth(`${AppConfig.ApiBaseUrl}api/comments/${bivakId}`);
            if (response.ok) {
                const data: Comment[] = await response.json();
                setComments(data);
            }
        } catch (error: any) {
            setErrorMessage(error.message);
            setIsErrorModalOpen(true);
        }
    };

    useEffect(() => {
        fetchComments();        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!comment.trim()) return;

        try {
            const formData = new FormData();
            formData.append('text', comment);

            const response = await AuthService.fetchWithAuth(`${AppConfig.ApiBaseUrl}api/comments/${bivakId}`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const newComment: Comment = await response.json();
                setComments(prev => [...prev, newComment]);
                setComment(''); // Clear the comment input after successful submission
            } else {
                const result = await response.json();
                alert('Failed to add comment: ' + result.message);
            }
        } catch (error: any) {
            setErrorMessage(AuthService.isLogged() ? error.message : ErrorMessages.NotSignedIn);
            setIsErrorModalOpen(true);
        }
    };

    return (
        <div>
            <p className='text-2xl font-bold mt-8'>Komentáře</p>
            <div className="max-w-2xl mx-auto bg-white p-6 rounded">
                <form onSubmit={handleSubmit} className="space-y-4">
                    <textarea
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        className="resize-none w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                        placeholder="Přidat komentář..."
                        rows={4}
                    ></textarea>
                    <button
                        type="submit"
                        className="w-full rounded text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-4 py-2 bg-black text-white hover:bg-gray-800 border-2 border-transparent"
                    >
                        Přidat komentář
                    </button>
                </form>
                <div className="mt-8 space-y-4">
                    {comments.map((c) => (
                        <div key={c.id} className="bg-gray-100 p-4 rounded">
                            <p className="mb-2 text-gray-700">{c.text}</p>
                            <small className="text-gray-500">
                            Přidal: {c.user?.customName ?? c.user?.userName} | {new Date(c.dateCreated).toLocaleDateString()} {new Date(c.dateCreated).toLocaleTimeString()}
                            </small>
                        </div>
                    ))}
                </div>

                <div>
                    <ErrorModal isOpen={isErrorModalOpen} message={errorMessage} onClose={() => setIsErrorModalOpen(false)} />
                </div>
            </div>
        </div>
    );
};

export default CommentComponent;
