import React, { useState } from 'react';
import AuthService from '../services/AuthService';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            await AuthService.login(email, password);
            navigate('/');
        } catch (error: any) {
            setError('Špatné heslo nebo email.');
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-100">
            <div className="max-w-md w-full space-y-8 bg-white p-8 rounded shadow-md">
                <h1 className="text-2xl font-bold mb-6 text-center">Přihlášení</h1>
                <p className="text-center mb-6">Vítej zpět, dobrodruhu! Přihlas se a pokračuj v objevování.</p>
                <form className="mt-8 space-y-6" onSubmit={handleLogin}>
                    <input type="hidden" name="remember" value="true" />
                    <div className="rounded -space-y-px">
                        <div>
                            <label htmlFor="email" className="sr-only">Email</label>
                            <input 
                                id="email" 
                                name="email" 
                                type="text" 
                                required 
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" 
                                placeholder="Email" 
                                value={email} 
                                onChange={e => setEmail(e.target.value)} 
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className="sr-only">Password</label>
                            <input 
                                id="password" 
                                name="password" 
                                type="password" 
                                required 
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" 
                                placeholder="Heslo" 
                                value={password} 
                                onChange={e => setPassword(e.target.value)} 
                            />
                        </div>
                    </div>
                    {error && <div className="text-red-500 text-sm mt-2 mb-3">{error}</div>}
                    <button 
                        type="submit" 
                        className="group relative w-full flex justify-center px-4 py-2 bg-black hover:bg-gray-800 text-white rounded transition"
                    >
                        Přihlásit
                    </button>
                </form>
            </div>
        </div>

    );
};

export default Login;
