import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import AuthService from '../services/AuthService';

const HeaderComponent = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const handleLogout = () => {
    AuthService.logout();
    navigate('/');
  };

  useEffect(() => {
    setMenuOpen(false);
  }, [location]);

  const renderNavLinks = () => (
    <>
      <Link to="/list" className="flex lg:px-3 py-2 items-center text-gray-600 hover:text-gray-900">
        Seznam
      </Link>
      <Link to="/map" className="flex lg:px-3 py-2 items-center text-gray-600 hover:text-gray-900">
        Mapa
      </Link>
      {AuthService.isLogged() && (
        <>
          <Link to="/add" className="flex lg:px-3 py-2 items-center text-gray-600 hover:text-gray-900">
            Přidat
          </Link>
          <Link to="/profile" className="flex lg:px-3 py-2 items-center text-gray-600 hover:text-gray-900">
            Profil
          </Link>
        </>
      )}
    </>
  );

  const renderAuthButtons = () => (
    <>
      {AuthService.isLogged() ? (
        <button
          onClick={handleLogout}
          className="bg-transparent border border-white hover:border-gray-400 text-black font-bold py-2 px-4 rounded"
        >
          Odhlásit se
        </button>
      ) : (
        <>
          <Link
            to="/login"
            className="bg-transparent border border-white hover:border-gray-400 text-black font-bold py-2 px-4 rounded"
          >
            Přihlášení
          </Link>
          <Link
            to="/register"
            className="rounded text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-4 py-2 bg-black text-white hover:bg-gray-800 border-2 border-transparent"
          >
            Registrace
          </Link>
        </>
      )}
    </>
  );

  return (
    <div className="max-w-screen-xl mx-auto px-5">
      <header className="bg-white text-black">
        <nav className="flex justify-between items-center">
          <div className="flex items-center space-x-8 py-4">
            <Link to="/" className="flex items-center font-bold">
              <img src="/logo192.png" alt="logo" className="max-h-full object-contain size-16" />
            </Link>
            <div className="hidden md:flex space-x-4">{renderNavLinks()}</div>
          </div>
          <div className="hidden md:flex space-x-4">{renderAuthButtons()}</div>
          <button
            className="md:hidden text-black focus:outline-none"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={menuOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16m-7 6h7'}
              />
            </svg>
          </button>
        </nav>
        {menuOpen && (
          <div className="md:hidden mt-2 space-y-2 flex flex-col items-center">
            {renderNavLinks()}
            {renderAuthButtons()}
          </div>
        )}
      </header>
    </div>
  );
};

export default HeaderComponent;