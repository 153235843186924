import { format } from "date-fns";
import AppConfig from "../appconfig";
import { Bivak } from "../entities/Bivak";

const ReservationItemComponent = ({bivak, date}: {bivak: Bivak, date: Date}) => {

    const imageUrl = bivak.images && bivak.images.length > 0
        ? `${AppConfig.ImagesPath}/images/${(bivak.images[0].fileName)}`
        : '/default-image.png';

    const formatDate = (date: Date): string => {
        return format(date, 'dd.MM.yyyy');
        };

    return (
        <div className="cursor-pointer border border-gray-200 rounded-md p-6 mb-4 shadow flex justify-between">
            <div className="text-left flex-1">
                <h3 className="text-2xl font-bold">{bivak.name}</h3>
                <p className="text-sm text-gray-600">Datum: {formatDate(date)}</p>
            </div>
            <img src={imageUrl} alt={bivak.name} className="w-48 h-48 object-cover ml-4 rounded-md" />
        </div>
    );
}

export default ReservationItemComponent