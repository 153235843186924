import AppConfig from "../appconfig";

class AuthService {
    static async login(email: string, password: string) {
        const response = await fetch(`${AppConfig.ApiBaseUrl}login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password })
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to login');
        }

        const data = await response.json();
        if (data.accessToken) {
            localStorage.setItem('token', JSON.stringify(data));
        }
        return data;
    }

    static async register(email: string, password: string) {
        const response = await fetch(`${AppConfig.ApiBaseUrl}register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password })
        });

        if (!response.ok) {
            const errorData = await response.json();
            if (response.status === 400 && errorData.errors) {
                const errors = errorData.errors;
                const errorKeys = Object.keys(errors);
                let errorMessage = '';

                for (let errorKey of errorKeys) {
                    switch(errorKey) { 
                        case 'InvalidEmail': { 
                           errorMessage += 'Neplatný email. \n'
                           break; 
                        }
                        case 'PasswordTooShort': { 
                            errorMessage += 'Heslo musí mít minimálně 8 znaků. \n'
                            break; 
                        }
                        case 'PasswordRequiresDigit': { 
                            errorMessage += 'Heslo musí obsahovat alespoň 1 číslici. \n'
                            break; 
                        }
                        case 'PasswordRequiresUpper': { 
                            errorMessage += 'Heslo musí obsahovat alespoň 1 velké písmeno. \n'
                            break; 
                        }
                         default: { 
                           errorMessage = 'Neznámá chyba.'; 
                           break; 
                        } 
                     } 
                }

                throw new Error(errorMessage);
            }
        }
    }

    static logout() {
        localStorage.removeItem('token');
    }

    static isLogged() : boolean {
        return localStorage.getItem('token') != null;
    }

    static fetchWithAuth = async (url: string, options: RequestInit = {}) => {
        const tokenData = localStorage.getItem('token');
        const token = tokenData ? JSON.parse(tokenData).accessToken : null;
    
        // Ensure headers exist
        if (!options.headers) {
          options.headers = new Headers();
        }
    
        if (token) {
          (options.headers as Headers).append('Authorization', `Bearer ${token}`);
        }
    
        if (!(options.body instanceof FormData) && !(options.headers as Headers).has('Content-Type')) {
          (options.headers as Headers).append('Content-Type', 'application/json');
        }
    
        const response = await fetch(url, options);

        if (response.status === 401 && token) {
            localStorage.removeItem('token');
        }

        return response;
      };
}

export default AuthService;
